<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="lease-content">
      <div class="left">
        <el-tabs v-model="activeName" tab-position="left" style="minheight: 200px">
          <el-tab-pane label="会员服务协议" name="hui_yuan_fu_wu"></el-tab-pane>
          <el-tab-pane label="免责声明" name="mian_ze_shen_ming"></el-tab-pane>
<!--          <el-tab-pane label="注销账号" name="zhu_xiao_zhang_hao"></el-tab-pane>-->
          <el-tab-pane label="帮助中心" name="bang_zhu_zhong_xin"></el-tab-pane>
        </el-tabs>
      </div>
      <div class="right">
        <div class="title">{{ title }}</div>

        <Editor ref="editor" :showBtn="false" :content.sync="content"></Editor>
        <div style="margin-top: 20px">
          <el-button type="primary" size="small" @click="save">保存</el-button>
          <el-button type="" size="small" @click="cancel">取消</el-button>
        </div>
      </div>
    </div>
    <div style="height: 40px"></div>
  </div>
</template>

<script>
import { getDetailAPI, editAPI } from './api'

import Editor from '@/components/editor/index.vue'
export default {
  name: 'List',
  components: { Editor },
  data() {
    return {
      activeName: 'hui_yuan_fu_wu',
      content: ''
    }
  },
  watch: {
    activeName: {
      immediate: true,
      handler(newV, oldV) {
        this.getDetail()
      }
    }
  },
  mounted() {},
  computed: {
    title() {
      let name = ''
      if (this.activeName == 'he_zuo_xie_yi') {
        name = '合作协议'
      } else if (this.activeName == 'yin_si_xie_yi') {
        name = '隐私协议'
      } else if (this.activeName == 'ji_cun_fu_wu_tiao_kuan') {
        name = '寄存服务条款'
      } else if (this.activeName == 'zhu_xiao_zhang_hao') {
        name = '注销账号'
      } else if (this.activeName == 'tui_ding_gui_ze_liu_cheng') {
        name = '退订规则流程'
      } else if (this.activeName == 'an_quan_bao_zhang') {
        name = '安全保障'
      } else if (this.activeName == 'shi_yong_zhi_nan') {
        name = '使用指南'
      } else if (this.activeName == 'yong_hu_fu_wu_xie_yi') {
        name = '用户服务协议'
      }

      return name
    }
  },
  methods: {
    async getDetail() {
      const { content } = await getDetailAPI(this.activeName)
      if (content) {
        this.content = content
      } else {
        this.content = ''
        this.$refs.editor.init()
      }
    },

    save() {
      let data = { key: this.activeName, content: this.content }

      editAPI(data).then(() => {
        this.$message.success('保存成功')
        this.getDetail()
      })
    },
    cancel() {
      this.getDetail()
      // 初始化编辑器
      this.$refs.editor.init()
    }
    // handlerArtSuccess(file) {
    //   console.log(file)
    //   let reader = new FileReader()
    //   reader.readAsArrayBuffer(file.raw)
    //   reader.onload = e => {
    //     const data = e.target.result
    //     mammoth.convertToHtml({ arrayBuffer: data }).then(res => {
    //       this.displayResult(res.value)
    //     })
    //   }
    // },
    // displayResult(html) {
    //   let newHtml = html
    //     .replace('<h1>', '<h1 style="text-align:center;">')
    //     .replace('<h2>', '<h2 style="text-align:center;">')
    //     .replace('<img', '<img style="width:20%;"')
    //     .replace(/<table>/g, '<table style="border-collapse:collapse;">')
    //     .replace(/<tr>/g, '<tr style="height:30px;">')
    //     .replace(/<td>/g, '<td style="border:1px solid #ccc">')
    //     .replace(/<p>/g, '<p style="text-indent:2em;">')
    //   this.content = newHtml
    //   this.$refs.editor.init()
    // }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  .lease-content {
    display: flex;
    .left {
      min-width: 150px;
      .el-tabs /deep/ {
        .el-tabs__nav-wrap.is-left::after {
          width: 0;
        }
      }
    }
    .right {
      flex: 1;
      .title {
        margin-bottom: 10px;
        margin-top: 8px;
        font-weight: 500;
        font-size: 15px;
      }
    }
  }
}
</style>
