import { http } from '@/http/axios.js'
export function getDetailAPI(key) {
  return http({
    url: '/api/manage/protocol/getContent',
    method: 'get',
    params: { key }
  })
}
export function editAPI(data) {
  return http({
    url: '/api/manage/protocol/setting',
    method: 'post',
    data
  })
}
